<template>
  <div>
    <!-- Hamburger Button -->
    <div class="hamburger-menu" @click="toggleMenu">
      <span></span>
      <span></span>
      <span></span>
    </div>

    <!-- Mobile Menu -->
    <div class="mobile-menu" v-if="menuVisible">
      <div class="language-selector-mobile">
        <select v-model="selectedLanguage">
          <option value="en">English</option>
          <option value="vi">Tiếng Việt</option>
        </select>
      </div>

      <nav class="mobile-nav">
        <router-link to="/" @click="toggleMenu">{{
          $t('App.navigationBar.home')
        }}</router-link>
        <!--<div class="dropdown-mobile">
          <a @click="toggleDropdownMobile">{{
            $t('App.navigationBar.whatWeDo')
          }}</a>
          <div class="dropdown-content-mobile" v-if="dropdownMobileVisible">
            <router-link to="/about" @click="toggleMenu">{{
              $t('App.navigationBar.about')
            }}</router-link
            >
            <router-link to="/services" @click="toggleMenu">{{
              $t('App.navigationBar.services')
            }}</router-link>
            <router-link to="/solutions" @click="toggleMenu">{{
              $t('App.navigationBar.solutions')
            }}</router-link>
          </div>
        </div>-->
        <router-link to="/about" @click="toggleMenu">{{
          $t('App.navigationBar.whatWeDo')
        }}</router-link>
        <router-link to="/industries" @click="toggleMenu">{{
          $t('App.navigationBar.industries')
        }}</router-link>
        <router-link to="/contact" @click="toggleMenu">{{
          $t('App.navigationBar.contact')
        }}</router-link>
      </nav>
    </div>

    <!-- Desktop Language Selector -->
    <div class="top-section-container">
      <div>
        <router-link to="/">
          <img
            class="center-image small-logo"
            alt="Logo"
            src="@/assets/images/logo-thien-y-original.png"
          />
        </router-link>
      </div>
      <div>
        <router-link to="/"
          ><h1 class="company-name">
            {{ $t('App.companyName2') }}
          </h1></router-link
        >
        <router-link to="/"
          ><h2>{{ $t('App.companySlogan') }}</h2></router-link
        >
      </div>
      <div>
        <div class="language-selector">
          <select v-model="selectedLanguage">
            <option value="en">English</option>
            <option value="vi">Tiếng Việt</option>
          </select>
        </div>
        <a
          href="https://www.facebook.com/Thienycorp2022/"
          target="_blank"
          rel="noopener"
        >
          <img src="@/assets/images/facebook-logo.svg" alt="Facebook Link" />
        </a>
        <a
          href="https://www.tiktok.com/@thienycorp?is_from_webapp=1&sender_device=pc/"
          target="_blank"
          rel="noopener"
        >
          <img src="@/assets/images/tiktok-logo.svg" alt="TikTok Link" />
        </a>
      </div>
    </div>

    <!-- Desktop Navigation -->
    <nav class="desktop-nav">
      <router-link to="/">{{ $t('App.navigationBar.home') }}</router-link> |
      <!--<div class="dropdown" @mouseleave="toggleDropdown(false)">
        <a @mouseover="toggleDropdown(true)">{{
          $t('App.navigationBar.whatWeDo')
        }}</a>
        <div class="dropdown-content" v-if="dropdownVisible">
          <router-link to="/about">{{
            $t('App.navigationBar.about')
          }}</router-link
          >
          <router-link to="/services">{{
            $t('App.navigationBar.services')
          }}</router-link>
          <router-link to="/solutions">{{
            $t('App.navigationBar.solutions')
          }}</router-link>
        </div>
      </div>-->
      <router-link to="/about">{{
        $t('App.navigationBar.whatWeDo')
      }}</router-link>
      |
      <router-link to="/industries">{{
        $t('App.navigationBar.industries')
      }}</router-link>
      |
      <router-link to="/contact">{{
        $t('App.navigationBar.contact')
      }}</router-link>
    </nav>

    <router-view />

    <hr />
    <br />

    <footer>
      © 2022-2024 {{ $t('App.companyName') }}. All rights reserved.
      <br />
      {{ $t('App.address') }} <br />
      {{ $t('App.phoneNumber') }} - {{ $t('App.taxID') }}
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedLanguage: 'vi', // Default to Vietnamese
      dropdownVisible: false,
      menuVisible: false,
      dropdownMobileVisible: false
    };
  },
  methods: {
    toggleDropdown(visible) {
      this.dropdownVisible = visible;
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
    toggleDropdownMobile() {
      this.dropdownMobileVisible = !this.dropdownMobileVisible;
    }
  },
  watch: {
    selectedLanguage(newVal) {
      this.$i18n.locale = newVal; // Update locale based on selection
    }
  }
};
</script>

<style>
@import url('./css/main-style.css');
@import url('./css/container-style.css');
@import url('./css/image-style.css');
@import url('./css/modal-style.css');
@import url('./css/navigation-dropdown-style.css');

.language-selector {
  margin-bottom: 0.5rem;
}

.language-selector select {
  border: 1px solid #ccc; /* Border for the select box */
  border-radius: 4px; /* Rounded corners */
  padding: 3px 5px; /* Padding for inner text */
}

/* For small screen size */
@media (max-width: 767px) {
  .language-selector {
    display: none;
  }
}
</style>
