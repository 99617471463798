import { createRouter, createWebHashHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/industries',
    name: 'industries',
    component: () => import('../views/IndustriesView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/ContactView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/WhatWeDoViews/AboutView.vue')
  },
  {
    path: '/services',
    name: 'services',
    component: () => import('../views/WhatWeDoViews/ServicesView.vue')
  },
  {
    path: '/solutions',
    name: 'solutions',
    component: () => import('../views/WhatWeDoViews/SolutionsView.vue')
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;
