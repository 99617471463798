<template>
  <div class="text-container">
    <!--<div class="pink-flower"></div>-->
    <h3>{{ $t('views.HomeView.title1') }}</h3>

    <p>
      {{ $t('views.HomeView.quote1') }} <br />
      <span class="author-name">{{ $t('views.HomeView.quote1Author') }}</span>
    </p>

    <br />

    <iframe
      v-if="$i18n.locale === 'vi'"
      width="554"
      height="313"
      src="https://www.youtube.com/embed/MRd1wFfjq1E?si=P04knePi1W8vXtuP"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>

    <iframe
      v-if="$i18n.locale === 'en'"
      width="545"
      height="305"
      src="https://www.youtube.com/embed/l_iNDoz-wh0?si=NI7V9MArn8ePCtlC"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>

  <div class="text-container hover-effect">
    <a
      href="https://www.facebook.com/Thienycorp2022/"
      target="_blank"
      rel="noopener"
    >
      <h3>{{ $t('views.HomeView.title3') }}</h3>
      <p>
        <span class="click-here">{{ $t('views.HomeView.clickHere') }}</span>
        {{ $t('views.HomeView.text3') }}
      </p>
    </a>
  </div>
</template>

<style scoped>
.click-here {
  font-size: 1.25rem;
  font-weight: bold;
  color: #ee3a37;
}

.author-name {
  font-weight: bold;
}

/* New style for hover effect */
.hover-effect:hover {
  transform: scale(1.1); /* Increase image size by 10% on hover */
  transition: transform 0.3s ease-in-out; /* Smooth transition effect */
  border: 0.15rem solid #8a3ff1; /* Solid border with a lighter shade of purple */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Light shadow for depth */
  background-image: url('@/assets/images/poinsettia.jpg');
  background-size: cover;
}

/* For small screen size */
@media (max-width: 767px) {
  .highlight,
  .highlight2 {
    font-size: 0.95rem;
  }

  .hover-effect:hover {
    background-image: url('@/assets/images/poinsettia.jpg');
  }

  iframe {
    width: 100%;
    height: 340px;
  }

  p {
    text-align: center;
  }
}

/* For very small screen size */
@media (max-width: 367px) {
  .hover-effect:hover {
    background-image: url('@/assets/images/poinsettia.jpg');
  }

  iframe {
    width: 100%;
    height: 200px;
  }
}
</style>
