import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createI18n } from 'vue-i18n';

import en from './locales/en.json';
import vi from './locales/vi.json';

const messages = {
  en,
  vi
};

const i18n = new createI18n({
  locale: 'vi',
  fallbackLocale: 'en',
  messages
});

createApp(App).use(i18n).use(router).mount('#app');
